import React, { useContext } from 'react'
import AppContext from './AppContext'

function Loading() {
  const {
    loading,
  } = useContext(AppContext)

  if(!loading) return null

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        left: '0px',
        top: '0px',
        position: 'fixed',
        zIndex: 1000,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{
        padding: '20px',
        backgroundColor: '#fff',
        border: '1px dashed #000',
        borderRadius: 5,
      }}>
        submitting your order...
      </div>
      
    </div>
  );
}

export default Loading
