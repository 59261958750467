import { useReducer, useCallback, useMemo, useState } from 'react'
import immer from 'immer'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import settings from './data/settings.json'
import products from './data/products.json'
import {
  getProductPrice,
  formatPrice,
} from './helper/utils'

let FORM_ENDPOINT = 'https://europe-west1-canaba-307110.cloudfunctions.net/order-form'

if(window.location.hostname == 'localhost') {
  FORM_ENDPOINT = 'http://localhost:8080'
}

const loadLocalCart = () => {
  const initialLocalCart = localStorage.getItem('cart')
  return initialLocalCart ? JSON.parse(initialLocalCart) : []
}

const saveLocalCart = (data) => localStorage.setItem('cart', JSON.stringify(data))

const initialState = {
  cart: loadLocalCart(),
}

const reducer = immer((state, action) => {
  if(!action.type) return state
  switch (action.type) {
    case 'addToCart':
      const existingProduct = state.cart.find(cartItem => cartItem.id == action.payload.id)
      if(existingProduct) {
        existingProduct.qty += action.payload.qty
      }
      else {
        state.cart.push(action.payload)
      }
      saveLocalCart(state.cart)
      return state
    case 'removeFromCart':
      state.cart = state.cart.filter(cartItem => cartItem.id != action.payload.id)
      saveLocalCart(state.cart)
      return state
    case 'cartFull':
      return state
    case 'resetCart':
      state.cart = []
      saveLocalCart(state.cart)
      return state
    default:
      throw new Error(`no reducer found for action ${action.type}`)
  }
})

const useApp = () => {

  const history = useHistory()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [filter, setFilter] = useState('any')
  const [loading, setLoading] = useState(false)

  const cartSummary = useMemo(() => {
    const rows = state.cart.map(item => {
      const id = item.id
      const product = item.product
      const price = getProductPrice(item.product)
      const qty = item.qty
      const total = qty * price
      return {
        id,
        product,
        price,
        qty,
        total,
        orderData: {
          name: product.display.title,
          qty: item.qty,
          price,
          priceTitle: '$' + formatPrice(price),
        }
      }
    })
    const total = rows.reduce((total, item) => total + item.total, 0)
    const cartTotalQty = rows.reduce((qty, item) => qty + item.qty, 0)

    let quantityError = ''

    if(cartTotalQty > settings.max_cart_qty) {
      quantityError = `You are not allowed more than ${settings.max_cart_qty} ${settings.unit}${settings.max_cart_qty == 1 ? '' : 's'}`
    }
    else if(cartTotalQty < settings.min_item_qty) {
      quantityError = `You must have at least ${settings.min_item_qty} ${settings.unit}${settings.min_item_qty == 1 ? '' : 's'} in your order`
    }

    return {
      rows,
      total,
      totalTitle: '$' + formatPrice(total),
      cartTotalQty,
      quantityError,
    }
  }, [
    state.cart,
  ])

  const onAddToCart = useCallback((payload) => {
    const newCartQuantity = cartSummary.cartTotalQty + payload.qty
    if(newCartQuantity > settings.max_cart_qty) {
      toast.error(`item not added. This will exceed the ${settings.max_cart_qty} ${settings.unit}${settings.max_cart_qty == 1 ? '' : 's'} maximum order limit!`)
      return
    }
    let addItemQty = payload.qty
    const existingProduct = state.cart.find(cartItem => cartItem.id == payload.id)
    if(existingProduct) {
      addItemQty += existingProduct.qty
    }
    console.log(`adding with existing ${addItemQty}`)
    if(addItemQty < settings.min_item_qty) {
      toast.error(`item not added. You must have at least ${settings.min_item_qty} ${settings.unit}${settings.min_item_qty == 1 ? '' : 's'} for each strain.`)
      return
    }
    dispatch({
      type: 'addToCart',
      payload,
    })
    toast.success('Item added to cart!')
  }, [
    state,
    dispatch,
    cartSummary,
  ])

  const onRemoveFromCart = useCallback((id) => {
    dispatch({
      type: 'removeFromCart',
      payload: {id},
    })
    toast.success('Item removed from cart!')
  }, [
    dispatch,
  ])

  const checkAgeVerification = useCallback(() => {
    const hasPassedVerification = localStorage.getItem('age_verification')
    if(!hasPassedVerification) history.push('/verify_age')
  }, [])

  const updateAgeVerification = useCallback(() => {
    localStorage.setItem('age_verification', 'yes')
    history.push('/')
  }, [])

  const filteredProducts = useMemo(() => {
    if(!filter || filter == 'any') return products
    return products.filter(p => {
      return p.filters[filter] === true
    })
  }, [
    products,
    filter,
  ])

  const submitOrder = useCallback(async ({
    token,
    details,
  }) => {

    setLoading(true)

    try {
      await axios({
        method: 'POST',
        url: FORM_ENDPOINT,
        data: {
          cart: cartSummary.rows.map(r => r.orderData),
          total: cartSummary.totalTitle,
          token,
          details,
        }
      })
      toast.success('Order submitted - Thank you.  We will respond to you within 12 hours to finalize this order.')
      dispatch({
        type: 'resetCart',
      })
    } catch(e) {
      let errorString = e.toString()
      if(e.response && e.response.data && e.response.data.error) errorString = e.response.data.error
      toast.error(`There was an error submitting your order: ${errorString}`)
    }
   
    setLoading(false)
  }, [
    cartSummary,
  ])

  return {
    dispatch,
    state,
    cartSummary,
    onAddToCart,
    onRemoveFromCart,
    checkAgeVerification,
    updateAgeVerification,
    products,
    filteredProducts,
    filter,
    setFilter,
    submitOrder,
    loading,
    setLoading,
  }
}

export default useApp