import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'
import AppContext from './AppContext'
import VerifyAge from './components/VerifyAge'
import useApp from './useApp'
import Loading from './Loading'

// Pages
// Home Pages
const Home = React.lazy(() => import("./components/pages/Home"));

// Innerpages
const Checkout = React.lazy(() => import("./components/pages/Checkout"));
const Orderdetails = React.lazy(() => import("./components/pages/Orderdetails"));
// Listing
const Listing = React.lazy(() => import("./components/pages/Listing"));
const Listingdetails = React.lazy(() => import("./components/pages/Listingdetails"));
// Additional
const AgeVerification = React.lazy(() => import("./components/pages/AgeVerification"));

function App() {
  const app = useApp()

  return (
    <AppContext.Provider value={ app }>
      <Router>
        <VerifyAge />
        <Suspense fallback={<div></div>}>
          <Switch>
            {/* Homepages */}
            <Route exact path="/" component={Home} />
            {/* Innerpages */}
            <Route path="/checkout" component={Checkout} />
            <Route path="/order-details" component={Orderdetails} />
            {/* Listing */}
            <Route path="/listing" component={Listing} />
            <Route path="/listing-detail/:id" component={Listingdetails} />
            {/* Shop */}
            {/* Additional */}
            <Route path="/verify_age" component={AgeVerification} />
            </Switch>
        </Suspense>
      </Router>
      <Loading />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </AppContext.Provider>
  );
}

export default App;
