import { useEffect } from 'react';
import useApp from '../useApp'

const Verify = () => {
  const app = useApp()
  useEffect(() => {
    app.checkAgeVerification()
  }, [])
  return null
}

export default Verify